import React from "react";
import { ViewerForm } from "../Helper/ViewerForm";

export const ClientDetails = ({client}) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-5">
      <ViewerForm
        label="Full Name"
        placeholder={client.buyerName}
        type="input"
      />
      <ViewerForm
        label="Email"
        placeholder={client.buyerEmail}
        type="input"
      />
      <ViewerForm
        label="Phone"
        placeholder={client.buyerPhone}
        type="input"
      />
    </div>
  );
};
