import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { btnStyle } from "../../Pages/AllRealtors";
import { formatCurrency } from "../../utils/formatCurrency";
import { UpdateCommission } from "./UpdateCommission";

export const PaymentHistory = ({ history}) => {
  const [search, setSearch] = useState("");
  const [editOpen, setEditOpen] = useState(false);
  const [sales, setSales] = useState([]);
  const [getId, setGetId] = useState("");

  const openEdit = (id) => {
    setEditOpen(true);
    setGetId(id);
  };
  const columns = [
    {
      name: "Date",
      cell: (val) => <span>{val.date.split("T")[0]}</span>,
      sortable: true,
    },
    {
      name: "Amount",
      cell: (val) => <span>{formatCurrency(val.deposit)}</span>,
      sortable: true,
    },
    {
      name: "Commission",
      cell: (val) => (val.commissionPaid ? "paid" : "unpaid"),
      sortable: true,
    },
    {
      name: "Action",
      cell: (val) => (
        <>
          <i
            onClick={() => openEdit(val._id)}
            class="ri-pencil-line text-xl cursor-pointer"
          ></i>
        </>
      ),
    },
  ];

  return (
    <div className="mt-5">
      <UpdateCommission
        historyId={getId}
        open={editOpen}
        handleClose={() => setEditOpen(false)}
      />
      <DataTable
        columns={columns}
        data={history}
        pagination
        fixedHeader
        responsive
        className="overflow-x-auto"
        striped
        highlightOnHover
      />
    </div>
  );
};
