import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import { AccountContext } from "../../Context/AccountContextProvider";
import { url } from "../../Api";
import axios from "axios";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { stateList } from "../../data/stateDate";

const EditPersonalInfo = () => {
  const { userAccount, userInfo } = useContext(AccountContext);
  const [sending, setSending] = useState(false);
  const validate = Yup.string().required("Field is required!");

  const onSubmit = (values) => {
    setSending(true);
    let data = new FormData();
    data.append("image", values.image);
    data.append("fullname", values.fullname);
    data.append("email", values.email);
    data.append("gender", values.gender);
    data.append("phone", values.phone);
    data.append("stateOfOrigin", values.stateOfOrigin);
    data.append("DOB", values.DOB);
    data.append("about", values.about);
    data.append("houseAdress", values.houseAdress);
    data.append("officeAdress", values.officeAdress);
    axios
      .patch(`${url}/user/profile/update/personal`, data, {
        headers: {
          Accept: "application/json",
          Authorization: `bearer ${userInfo?.token}`,
        },
      })
      .then((response) => {
        toast.success(response.data.message);
        setSending(false);
        window.location.reload();
      })
      .catch((err) => {
        toast.error(err.message);
        setSending(false);
      });
  };

  const validationSchema = Yup.object({
    fullname: validate,
    email: validate,
    phone: validate,
  });

  const initialValues = {
    fullname: userAccount?.fullname,
    email: userAccount?.email,
    gender: userAccount?.gender,
    phone: userAccount?.phone,
    stateOfOrigin: userAccount?.stateOfOrigin,
    DOB: userAccount?.DOB,
    about: userAccount?.about,
    houseAdress: userAccount?.houseAdress,
    officeAdress: userAccount?.officeAdress,
    image: "",
  };
  const { handleChange, handleSubmit, values, errors, setFieldValue } =
    useFormik({ initialValues, onSubmit, validationSchema });
  return (
    <>
      <div>
        <h4 className="font-semibold text-lg pb-4">Personal Details</h4>
        <form onSubmit={handleSubmit}>
          <div className="form-control">
            <label>Profile picture</label>
            <input
              type="file"
              name="image"
              placeholder="Enter property picture"
              onChange={(event) => {
                setFieldValue("image", event.target.files[0]);
              }}
            />
            {errors.image ? <p className="errorMsg">{errors.image}</p> : null}
          </div>
          <div className="form-control">
            <label>Full name</label>
            <input
              type="text"
              name="fullname"
              onChange={handleChange}
              value={values?.fullname}
            />
            {errors.fullname ? (
              <p className="errorMsg">{errors.fullname}</p>
            ) : null}
          </div>

          <div className="form-control">
            <label>Email address</label>
            <input
              type="email"
              name="email"
              onChange={handleChange}
              value={values?.email}
            />
            {errors.email ? <p className="errorMsg">{errors.email}</p> : null}
          </div>
          <div className="form-control">
            <label>Gender</label>
            <select
              name="gender"
              onChange={handleChange}
              value={values?.gender}
            >
              <option value="">-select-</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
            {errors.gender ? <p className="errorMsg">{errors.gender}</p> : null}
          </div>

          <div className="form-control">
            <label>Phone number</label>
            <input
              type="text"
              name="phone"
              onChange={handleChange}
              value={values?.phone}
            />
            {errors.phone ? <p className="errorMsg">{errors.phone}</p> : null}
          </div>
          <div className="form-control">
            <label>State of residence</label>
            <select onChange={handleChange} value={values?.stateOfOrigin}>
              <option value="">Select state</option>
              {stateList.map((state) => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
            </select>
            {errors.stateOfOrigin ? (
              <p className="errorMsg">{errors.stateOfOrigin}</p>
            ) : null}
          </div>

          <div className="form-control">
            <label>Date of birth</label>
            <input
              type="date"
              name="DOB"
              onChange={handleChange}
              value={values?.DOB}
            />
            {errors.DOB ? <p className="errorMsg">{errors.DOB}</p> : null}
          </div>

          <div className="form-control">
            <label>About me</label>
            <textarea
              name="about"
              onChange={handleChange}
              value={values?.about}
            />

            {errors.about ? <p className="errorMsg">{errors.about}</p> : null}
          </div>
          <div className="form-control">
            <label>House address</label>
            <input
              type="text"
              name="houseAdress"
              onChange={handleChange}
              value={values?.houseAdress}
            />

            {errors.houseAdress ? (
              <p className="errorMsg">{errors.houseAdress}</p>
            ) : null}
          </div>
          <div className="form-control">
            <label>Office address</label>
            <input
              type="text"
              name="officeAdress"
              onChange={handleChange}
              value={values?.officeAdress}
            />
            {errors.officeAdress ? (
              <p className="errorMsg">{errors.officeAdress}</p>
            ) : null}
          </div>

          <button type="submit" className="button w-full">
            {sending ? "Updating..." : "Update"}
          </button>
        </form>
      </div>
    </>
  );
};

export default EditPersonalInfo;
