import React, { useCallback, useContext, useEffect, useState } from "react";
import { Autocomplete, Modal, TextField } from "@mui/material";
import axios from "axios";
import { url } from "../../Api";
import { toast } from "react-toastify";
import { PageContext } from "../../Context/PageContextProvider";
import { Box } from "@mui/system";
import { useFetchAllDataForSale } from "../../hooks/useFetchAllDataForSale";
import { formatCurrency } from "../../utils/formatCurrency";

const CreateSalesRecord = ({ open, handleClose }) => {
  const { userInfo } = useContext(PageContext);
  const [user, setUser] = useState("");
  const [property, setProperty] = useState("");
  const [deposit, setDeposit] = useState("");
  const [status, setStatus] = useState("");
  const [commissionPaid, setCommissionPaid] = useState("");
  const [buyerName, setBuyerName] = useState("");
  const [buyerEmail, setBuyerEmail] = useState("");
  const [buyerPhone, setBuyerPhone] = useState("");
  const [duration, setDuration] = useState("");
  const [date, setDate] = useState("");
  const [sending, setSending] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setSending(true);
    let data = {
      property,
      user,
      deposit: parseInt(deposit),
      duration,
      date,
      buyerDetails: {
        buyerName,
        buyerEmail,
        buyerPhone,
      },
      commissionPaid: commissionPaid === "true" ? true : false,
      status,
    };

    axios({
      url: `${url}/sales/create`,
      method: "post",
      data,
      headers: {
        authorization: `bearer ${userInfo?.token}`,
      },
    })
      .then((result) => {
        setSending(false);
        toast.success("sales added successfully");
        window.location.reload();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setSending(false);
      });
  };

  const { realtors, properties } = useFetchAllDataForSale(userInfo);

  return (
    <Modal open={open} onClose={handleClose}>
      <div className="CModal scrollBar" style={{ maxWidth: 700 }}>
        <div className="flex justify-between items-center mb-7">
          <h5 className="font-semibold text-accent text-lg">
            Create Sales Record
          </h5>
          <i
            className="fas fa-times cursor-pointer text-xl"
            onClick={handleClose}
          ></i>
        </div>
        <form
          className="grid grid-cols-1 lg:grid-cols-2 gap-x-4"
          onSubmit={onSubmit}
        >
          <div className="form-control">
            <label>Property</label>
            <Autocomplete
              loading="Loading"
              onChange={(e, v) => setProperty(v._id)}
              size="small"
              options={properties}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} required placeholder="Select Property" />
              )}
              renderOption={(props, option) => (
                <div {...props}>
                  {option.name}{" "}
                  <span className="text-xs pl-2">
                    ({formatCurrency(option.price)})
                  </span>
                </div>
              )}
            />
          </div>

          <div className="form-control">
            <label>Realtor</label>

            <Autocomplete
              loading="Loading"
              onChange={(e, v) => setUser(v._id)}
              size="small"
              options={realtors}
              getOptionLabel={(option) => option.fullname || "Loading"}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select realtor" required />
              )}
              renderOption={(props, option) => (
                <div {...props} className="border-b pb-1 mb-1 pl-1">
                  {option.fullname}{" "}
                  <span className="text-xs pl-2">({option.phone})</span>
                </div>
              )}
            />
          </div>

          <div className="form-control">
            <label>Amount Paid</label>
            <input
              type="number"
              onChange={(e) => setDeposit(e.target.value)}
              required
            />
          </div>
          <div className="form-control">
            <label>Date</label>
            <input
              type="date"
              onChange={(e) => setDate(e.target.value)}
              required
            />
          </div>

          <div className="form-control">
            <label>Payment duration</label>
            <select onChange={(e) => setDuration(e.target.value)} required>
              <option value="">-select--</option>
              <option value="outright">Outright</option>
              <option value="3 months">3 months</option>
              <option value="6 months">6 months</option>
              <option value="12 months">12 months</option>
              <option value="24 months">24 months</option>
            </select>
          </div>

          <div className="form-control">
            <label>Buyers name</label>
            <input
              type="text"
              onChange={(e) => setBuyerName(e.target.value)}
              required
            />
          </div>

          <div className="form-control">
            <label>Buyers phone</label>
            <input
              type="tel"
              onChange={(e) => setBuyerPhone(e.target.value)}
              required
            />
          </div>

          <div className="form-control">
            <label>Buyers email</label>
            <input
              type="email"
              onChange={(e) => setBuyerEmail(e.target.value)}
              required
            />
          </div>

          <div className="form-control">
            <label>Payment status</label>
            <select onChange={(e) => setStatus(e.target.value)} required>
              <option value="">-select Payment status--</option>
              <option value="Completed">Completed</option>
              <option value="Incomplete">Incomplete</option>
            </select>
          </div>

          <div className="form-control">
            <label>Commission Paid</label>
            <select
              onChange={(e) => setCommissionPaid(e.target.value)}
              required
            >
              <option value="">-select Commission status--</option>
              <option value="true">paid</option>
              <option value="false">unpaid</option>
            </select>
          </div>

          <div className="flex items-center">
            <button type="submit" className="button">
              {sending ? "Sending" : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CreateSalesRecord;
