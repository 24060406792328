import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import PageToper from "../Components/PageToper";
import DashboardLayout from "../Layout/DashboardLayout";
import TabContext from "@material-ui/lab/TabContext";
import { Tab } from "@mui/material";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import { SRealtorDetails } from "../Components/SalesRecordCom/SRealtorDetails";
import { ClientDetails } from "../Components/SalesRecordCom/ClientDetails";
import { SPropertyDetails } from "../Components/SalesRecordCom/SPropertyDetails";
import { SalesSummary } from "../Components/SalesRecordCom/SalesSummary";
import { PaymentHistory } from "../Components/SalesRecordCom/PaymentHistory";
import { useParams } from "react-router-dom";
import { url } from "../Api";
import axios from "axios";
import { PageContext } from "../Context/PageContextProvider";
import { SalesUpLine } from "../Components/SalesRecordCom/SalesUpLine";

export const SingleSalesRecord = () => {
  const { userInfo } = useContext(PageContext);
  const [value, setValue] = useState("1");
  const [saleDetails, setSaleDetails] = useState({});
  const [record, setRecord] = useState({})
  const changeTab = (event, newValue) => {
    setValue(newValue);
  };
  const params = useParams();
  useEffect(() => {
    axios
      .get(`${url}/sales/single/view/${params.id}`, {
        headers: {
          Accept: "application/json",
          Authorization: `bearer ${userInfo?.token}`,
        },
      })
      .then((response) => {
        let val = response.data.sales;
        setSaleDetails(val);
        setRecord(response.data.record)
      })
      .catch((err) => {});
  }, [userInfo?.token, params.id]);
  return (
    <DashboardLayout>
      <div className="Container">
        <PageToper
          title="Sale Details"
          desc="Sale breakdown"
          link="/sales-records"
          adminAccount
        />
        <div>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "#ccc" }}>
              <TabList onChange={changeTab}>
                <Tab label="Summary" value="1" />
                <Tab label="Payment history" value="5" />
                <Tab label="Client details" value="2" />
                <Tab label="Property" value="3" />
                <Tab label="Realtor" value="4" />
                <Tab label="Upline" value="6" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <SalesSummary sales={saleDetails} />
            </TabPanel>
            <TabPanel value="5">
              <PaymentHistory
                history={record}
              />
            </TabPanel>
            <TabPanel value="2">
              <ClientDetails client={saleDetails?.buyerDetails} />
            </TabPanel>
            <TabPanel value="3">
              <SPropertyDetails fullDetails={saleDetails?.property} />
            </TabPanel>
            <TabPanel value="4">
              <SRealtorDetails userAccount={saleDetails?.user} />
            </TabPanel>
            <TabPanel value="6">
              <SalesUpLine userId={saleDetails?.user?._id} />
            </TabPanel>
          </TabContext>
        </div>
      </div>
    </DashboardLayout>
  );
};
